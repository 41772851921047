@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');
.app-text{
    padding: 0;
    margin: 0;
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    color: #3E3F42;
    display: inline-block;
}
.txt:hover {
    text-decoration: underline;
    color: #018f6b;
}
._medium{
    font-weight: 500;
}
._bold{
    font-weight: 600;
}
._30{
    font-size: 30px;
}
._26{
    font-size: 26px;
}
._22{
    font-size: 22px;
}
._20{
    font-size: 20px;
}
._18{
    font-size: 18px;
}
._16{
    font-size: 16px;
}
._14{
    font-size: 14px;
}
._12{
    font-size: 12px;
}
._11{
    font-size: 11px;
}
._c90{
    color: #6B6C6F
}
._c50{
    color: #9EA0A5
}
._c0{
    color: #FFFFFF;
}
._g90{
    color: #03CA98;
}
._b100{
    color: #3C3E5A;
}
._r100{
    color: #E6492D;
}
._g100{
    color: #34AA44!important;
}
._o100{
    color: #F6AB2F!important;
}
._center{
    text-align: center;
}
._left{
    text-align: left;
}


/* cirle */
.circle{
    max-width: 30px;
    max-height: 30px;
    min-width: 30px;
    min-height: 30px;
    border-radius: 30px;
}
.still{
    border: 1px solid #EAEDF3;
}
.inProgress{
    background-color: #fff;
    border: 1px solid #EAEDF3;
    box-shadow: 0 1px 3px #0000000A;
}
.done{
    background-color: #03CA98;
    border: 1px solid #fff;
    box-shadow: 0 1px 3px #0000000A;
}
._school_icon{
    height: 50px;
    width: 50px;
    margin-right: 15px;
    border: 1px solid #D8DCE6;
    border-radius: 4px;
}
.react-tel-input{
    height: 38px!important;
}
.react-tel-input input{
    height: 38px!important;
    color: #3E3F42!important;
    font-family: 'Roboto', sans-serif;
    border: 1px solid #D8DCE6;
}
.react-tel-input .form-control{
    font-family: 'Roboto', sans-serif;
    border: 1px solid #D8DCE6;
}
.react-tel-input .flag-dropdown{
    background-color: #fff;
    border: 1px solid #D8DCE6;
    border-radius: 4px 0 0 4px;
}
.react-tel-input .form-control:focus{
    box-shadow: none;
}
._chip{
    background-color: #FBFBFD;
    margin-right: 12px;
    border-radius: 50px;
    border: 1px solid #EAEDF3;
    padding: 2px 10px;
}
._cardskeleton{
    width: 255px;
    height: 210px;
    background-color: #fff;
    border-radius: 4px;
    border: 1px solid #EAEDF3;
    margin-bottom: 22px;
}

._cardskeleton_sm{
    width: 100%;
    height: 210px;
    background-color: #fff;
    border-radius: 4px;
    border: 1px solid #EAEDF3;
    margin-bottom: 22px;
}
